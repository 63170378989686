/* You can add global styles to this file, and also import other style files */
@import "~ng-zorro-antd/ng-zorro-antd.less";

//公用样式
.card-wrapper{
    width: 100%;
    // height: 200px;
    margin: 12px;
}
.work-position-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 90vw;
}
.check-item{
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
}
.ok{
    color: green;
}
.not-ok{
    color: red;
}
.end-line{
    width: 100%;
    text-align: right;
    margin-top: 16px;
}
.margin-left-btn{
    margin-left: 16px;
}
.ant-row{
    margin: 0 !important;
    width: 100%;
}
.axis-card-wrapper .ant-card-body{
    height: 84%;
}
.ant-divider{
    margin: 16px 0 !important;
}
.ant-card-body{
    padding: 10px !important;
}